const ItemType = {
    BAG: "BAG",
    PACKAGE: "PACKAGE",
    CART: "CART",
    PALLET: "PALLET",
    isBag: (itemType) => {
        return ItemType.BAG === itemType;
    },
    isPackage: (itemType) => {
        return ItemType.PACKAGE === itemType;
    }
}

const ItemStatus = {
    STOWED: "STOWED",
    PICKED: "PICKED",
    MISSED: "MISSED",
    MISSED_TEMP: "MISSED_TEMP",
    DAMAGED: "DAMAGED",
    STAGED: "STAGED",
    STAGE_BUFFERED: "STAGE_BUFFERED",
    isCompleted: (itemStatus) => {
        return !(itemStatus == null
            || ItemStatus.STOWED === itemStatus
            || ItemStatus.MISSED_TEMP === itemStatus);
    },
    isMissing: (itemStatus) => {
        return ItemStatus.MISSED === itemStatus
            || ItemStatus.MISSED_TEMP === itemStatus;
    },
    isMissingEver: (missedEver, itemStatus) => {
        return missedEver || ItemStatus.isMissing(itemStatus);
    },
    isTempMissing: (itemStatus) => {
        return ItemStatus.MISSED_TEMP === itemStatus;
    },
    isPicked: (itemStatus) => {
        return ItemStatus.PICKED === itemStatus
            || ItemStatus.STAGED === itemStatus
            || ItemStatus.STAGE_BUFFERED === itemStatus;
    }
}

const WeightUnit = {
    KG: "kg",
    LB: "lb"
}

const WeightCategory = {
    HEAVY: "HEAVY",
    NORMAL: "NORMAL"
}

export {ItemType, ItemStatus, WeightUnit, WeightCategory};
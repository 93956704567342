import {ItemStatus, ItemType} from "../../models/enums/item-enums";
import UserProfileDetail from "../../bridge/user-profile-detail";
import {ROUTE_PATH} from "../../routing";
import {FeatureManager} from "@amzn/dolphin-web-framework";
import AppDataStore from "../../app-data";

export function assureMinimalValidationsForPackage(pickItem) {
    // item and it's location should be present
    // item should be a package and stowed as well
    return pickItem && (pickItem.aisle || pickItem.bin || pickItem.rack)
        && ItemType.isPackage(pickItem.itemType)
        && ItemStatus.STOWED === pickItem.itemStatus;
}

export function assurePackagesAreInSameAisleAndRack(itemScanned, pickItem) {
    // item should be a package and stowed
    // aisle should match
    // both should either have rack or not
    // rack should match if they exist
    return pickItem && itemScanned
        && ItemType.isPackage(itemScanned.itemType) && ItemStatus.STOWED === itemScanned.itemStatus
        && itemScanned.aisle === pickItem.aisle
        && !!itemScanned.rack === !!pickItem.rack
        && (itemScanned.rack ? itemScanned.rack === pickItem.rack : true);
}

export function getNextBag(itemList, pickItem) {
    // find the next bag after the current pickItem

    if (FeatureManager.isFeatureEnabled(FeatureManager.Features.PICKING_MULTIPLE_BAG) ||
        (FeatureManager.isFeatureEnabled(FeatureManager.Features.PICKING_MULTIPLE_BAG_WITH_SETTING) &&
            UserProfileDetail.isUserEnabledPickingMultipleBag())) {
        let found = !pickItem; // init as false if we have pickItem
        for (const item of itemList) {
            // check if the item type is bag and it is either STOWED or no status
            if (found && ItemType.isBag(item.itemType)
                && (!item.itemStatus || ItemStatus.STOWED === item.itemStatus)) {
                return item;
            }
            found = found || !pickItem || item.itemId === pickItem.itemId; // update if we found the current pickItem
        }
    }
    return null;
}

/**
 *
 * get next item to be picked.
 */
export function getNextPickItemInTheList(itemList) {
    if (itemList && itemList.length) {
        for (const item of itemList) {
            if (!item.itemStatus
                || item.itemStatus === ItemStatus.STOWED) {
                return item;
            }
        }
    }
    return null;
}

export function shouldShowMixedCartFlashScreen(workflow, activeCart, pickItem) {
    const mixedCartFlashScreenTrigger = (activeCart.mixedCartRowChangeThreshold) ? activeCart.mixedCartRowChangeThreshold : 3;
    const isMixedCartFlashScreenEnabled = FeatureManager.isFeatureEnabled(FeatureManager.Features.MIXED_CART_FLASH_SCREEN);
    const itemsPickedInCart = (activeCart.childCount) ? activeCart.childCount : 0;

    //If 3rd(mixedCartRowChangeThreshold) bag is being picked for a mixed cart show the new way for loading mixed carts.
    return (isMixedCartFlashScreenEnabled && itemsPickedInCart + 1 === mixedCartFlashScreenTrigger && ItemType.BAG === pickItem.itemType && isMixedCart(workflow));
}

export function isMixedCart(workflow) {
    const activeCart = AppDataStore.getActiveCart();
    if (workflow.completedOVCount + workflow.tempMissingOVCount > 0) {
        return false;
    }
    const bagsRemainingTobePicked = workflow.totalBagCount - (workflow.completedBagCount + workflow.tempMissingBagCount);
    const maxBagsInMixedCart = (activeCart.mixedCartMaxBags) ? (activeCart.mixedCartMaxBags) : 5;
    const itemsPickedInCart = (activeCart.childCount) ? activeCart.childCount : 0;
    return (itemsPickedInCart + bagsRemainingTobePicked > 0 && itemsPickedInCart + bagsRemainingTobePicked <= maxBagsInMixedCart && workflow.totalOVCount > 0);
}

/**
 * The function should get the next/current Item and should return the same item if it was not picked
 * @returns {PickItem}
 */
export function getItemToPick(itemList) {
    if (itemList && itemList.length) {
        for (const item of itemList) {
            if (!item.itemStatus
                || item.itemStatus === ItemStatus.STOWED
                || item.itemStatus === ItemStatus.MISSED_TEMP) {
                return item;
            }
        }
    }
    return null;
}

/**
 * The function should get the next Item and should return the same item if it was not picked
 * @returns {PickItem}
 */
export function getPickItemById(itemList, itemId) {
    if (itemList && itemList.length) {
        for (const item of itemList) {
            if (item.itemId === itemId) {
                return item;
            }
        }
    }
    return null;
}

/**
 * The function should get the next Item and should return the same item if it was not picked
 * @returns {PickItem}
 */
export function getPickItemByScannableId(itemList, scannableId) {
    if (itemList && itemList.length) {
        for (const item of itemList) {
            if (item.scannableIds) {
                for (let itemScannableId of item.scannableIds) {
                    if (itemScannableId === scannableId) return item;
                }
            }
        }
    }
    return null;
}

export function tempMarkMissing(itemList, pickItem) {
    let newItemList = [], missing = [];
    for (let i = 0; i < itemList.length; ++i) {
        const item = itemList[i];
        // if we found the missing bag, or missing packages from the aisle and rack
        if ((ItemType.isBag(pickItem.itemType) && item.itemId === pickItem.itemId) ||
            (ItemType.isPackage(pickItem.itemType) && ItemType.isPackage(item.itemType) &&
                ItemStatus.STOWED === item.itemStatus && // marking missing only if the package is just STOWED
                item.aisle === pickItem.aisle && pickItem.rack === item.rack)) {
            missing.push(item);
        } else {
            newItemList.push(item);
        }
    }
    for (const item of missing) {
        item.itemStatus = ItemStatus.MISSED_TEMP;
        newItemList.push(item);
    }
    return newItemList;
}

export function markAsMissing(itemList, itemId) {
    // get the item from the
    const item = getPickItemById(itemList, itemId);
    item.itemStatus = ItemStatus.MISSED; // item is updated in the itemList as it is a reference
    return itemList;
}

export function markAsPicked(itemList, itemId) {
    // get the item from the
    const item = getPickItemById(itemList, itemId);
    item.itemStatus = ItemStatus.PICKED; // item is updated in the itemList as it is a reference
    return itemList;
}

export function markAsDamaged(itemList, itemId) {
    // get the item from the
    const item = getPickItemById(itemList, itemId);
    item.itemStatus = ItemStatus.DAMAGED; // item is updated in the itemList as it is a reference
    return itemList;
}

export function continuePicking(props, picklist, lastItem, lastItemStatus) {
    // we have are transitioning
    let nextPickItem = getNextPickItemInTheList(picklist.itemList);
    // if nextPickItem is null, we don't have stowed items, try missing
    if (nextPickItem == null) {
        nextPickItem = getItemToPick(picklist.itemList);
        // if the item is not missing_temp, make null so we go to do_picking
        if (nextPickItem && nextPickItem.itemStatus !== ItemStatus.MISSED_TEMP) {
            nextPickItem = null;
        }
    }
    // if itemTypes are different bag->package
    // if lastItemStatus is non missing
    if (nextPickItem && nextPickItem.itemType !== lastItem.itemType && !ItemStatus.isMissing(lastItemStatus)) {
        let url = ROUTE_PATH.WITH_DO_PICKING.WORKFLOW_TRANSITION;
        url = url.replace(':lastItemId', lastItem.scannableIds[0]);
        url = url.replace(':nextItemId', nextPickItem.scannableIds[0]);
        props.history.replace(url);
    } else {
        props.history.replace(ROUTE_PATH.DO_PICKING);
    }
}


export function checkForBarcodeEntered(onScan) {
    const barcodeEntered = AppDataStore.getBarcodeEntered();
    if (barcodeEntered) {
        onScan(barcodeEntered);
        AppDataStore.setBarcodeEntered(null);
    }
}

export function getUnPickedPackagesByPickItem(itemList, pickItem) {
    const itemsList = [];
    for (const item of itemList) {
        if (assurePackagesAreInSameAisleAndRack(item, pickItem)) {
            itemsList.push(item);
        }
    }
    return itemsList;
}


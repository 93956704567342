import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {Loader} from "@amzn/dolphin-uiweb-framework";
import AppLauncher from "./components/app-launcher";

const DirectedPickEntry = React.lazy(() => import('./components/pre-pick/directed-pick-entry'));
const PrePickConfirmation = React.lazy(() => import('./components/pre-pick/pre-pick-confirmation'));
const InputRoute = React.lazy(() => import('./components/pre-pick/input-route'));
const SelectNode = React.lazy(() => import('./components/pre-pick/select-node'));
const AddCart = React.lazy(() => import('./components/pre-pick/add-cart'));
const AddCartOverride = React.lazy(() => import('./components/pre-pick/add-cart-override'));
const CartConfirmation = React.lazy(() => import('./components/pre-pick/cart-confirmation'));
const DoPicking = React.lazy(() => import('./components/execute-pick/do-picking'));
const PickingComplete = React.lazy(() => import('./components/post-pick/picking-complete'));

export const ROUTE_PATH = {
    INDEX: "/",
    HOME: "/home",
    ADD_CART_FOR_PICKING: "/add-cart",
    ADD_CART_OVERRIDE: "/add-cart-override/:scannedText",
    CART_CONFIRMATION: "/cart-confirmation",
    PRE_PICK_CONFIRMATION: "/pre-pick-confirmation",
    INPUT_ROUTE: "/input-route",
    SELECT_NODE: "/select-node",
    DO_PICKING: "/do-picking",
    PICKING_COMPLETE: "/picking-complete"
};

ROUTE_PATH.WITH_DO_PICKING = {
    PICK_ITEM: `${ROUTE_PATH.DO_PICKING}/:itemId`,
    WORKFLOW_TRANSITION: `${ROUTE_PATH.DO_PICKING}/workflow-transition/:lastItemId/:nextItemId`,
    ADD_CART: `${ROUTE_PATH.DO_PICKING}/add-cart`,
    SCAN_CART_FOR_PICKING: `${ROUTE_PATH.DO_PICKING}/scan-cart-for-picking/:itemId/:wrongScan`,
    STAGE_CART: `${ROUTE_PATH.DO_PICKING}/stage-cart`,
    MISSING_ITEM: `${ROUTE_PATH.DO_PICKING}/missing-item/:itemId`,
    BARCODE_ENTRY: `${ROUTE_PATH.DO_PICKING}/barcode-entry/:itemId`,
    DAMAGED_MODAL: `${ROUTE_PATH.DO_PICKING}/damaged-modal`,
    PICKING_ISSUE: `${ROUTE_PATH.DO_PICKING}/picking-issue/:title/:description`,
    MIXED_CART_LOADING_ORDER:`${ROUTE_PATH.DO_PICKING}/mixed-cart-loading-order/:itemId`
};

function Routing(props) {

    // The sequencing matters in the routing
    return <React.Suspense fallback={<Loader/>}>
        <Switch>
            <Route path={ROUTE_PATH.INPUT_ROUTE} component={InputRoute}/>
            <Route path={ROUTE_PATH.SELECT_NODE} component={SelectNode}/>
            <Route path={ROUTE_PATH.PRE_PICK_CONFIRMATION} component={PrePickConfirmation}/>
            <Route path={ROUTE_PATH.ADD_CART_FOR_PICKING} component={AddCart}/>
            <Route path={ROUTE_PATH.ADD_CART_OVERRIDE} component={AddCartOverride}/>
            <Route path={ROUTE_PATH.CART_CONFIRMATION} component={CartConfirmation}/>
            <Route path={ROUTE_PATH.DO_PICKING} component={DoPicking}/>
            <Route path={ROUTE_PATH.PICKING_COMPLETE} component={PickingComplete}/>

            {/* The last entry is the home page*/}
            <Route path={ROUTE_PATH.HOME} component={DirectedPickEntry}/>
            <Route path={ROUTE_PATH.INDEX} component={AppLauncher}/>
        </Switch>
    </React.Suspense>;
}

export default withRouter(Routing);

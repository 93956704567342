import {Constants, PWADomainHelper} from "@amzn/dolphin-web-framework";
import AppDataStore, {APP_DATA_KEYS} from "../../app-data";
import {Modules} from "../../constants/Modules";

const BASE_URL_ENV_REGION_MAP = new Map();
// "REGION-ENV" => baseUrl
BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_NA}-${Constants.Stage.BETA}`, Constants.DolphinURL.NA.PREPROD);
BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_EU}-${Constants.Stage.BETA}`, Constants.DolphinURL.EU.PREPROD);
BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_IN}-${Constants.Stage.BETA}`, Constants.DolphinURL.IN.PREPROD);

BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_NA}-${Constants.Stage.GAMMA}`, Constants.DolphinURL.NA.PREPROD);
BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_EU}-${Constants.Stage.GAMMA}`, Constants.DolphinURL.EU.PREPROD);
BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_FE}-${Constants.Stage.GAMMA}`, Constants.DolphinURL.FE.PREPROD);
BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_IN}-${Constants.Stage.GAMMA}`, Constants.DolphinURL.IN.PREPROD);

BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_NA}-${Constants.Stage.PROD}`, Constants.DolphinURL.NA.PROD);
BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_EU}-${Constants.Stage.PROD}`, Constants.DolphinURL.EU.PROD);
BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_FE}-${Constants.Stage.PROD}`, Constants.DolphinURL.FE.PROD);
BASE_URL_ENV_REGION_MAP.set(`${Constants.Region.REGION_IN}-${Constants.Stage.PROD}`, Constants.DolphinURL.IN.PROD);


const KATAL_LOGGER_URL_MAP = new Map();
// "STAGE-REGION" => url
KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_NA}-${Constants.Stage.BETA}`, "https://31f0urkvg5.execute-api.us-west-2.amazonaws.com/prod/v1/log");
KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_EU}-${Constants.Stage.BETA}`, "https://31f0urkvg5.execute-api.us-west-2.amazonaws.com/prod/v1/log");
KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_FE}-${Constants.Stage.BETA}`, "https://31f0urkvg5.execute-api.us-west-2.amazonaws.com/prod/v1/log");
KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_IN}-${Constants.Stage.BETA}`, "https://31f0urkvg5.execute-api.us-west-2.amazonaws.com/prod/v1/log");

KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_NA}-${Constants.Stage.GAMMA}`, "https://g7hy0lnezi.execute-api.us-east-1.amazonaws.com/prod/v1/log");
KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_EU}-${Constants.Stage.GAMMA}`, "https://99rhmafihd.execute-api.eu-west-1.amazonaws.com/prod/v1/log");
KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_FE}-${Constants.Stage.GAMMA}`, "https://fts7omxhwa.execute-api.us-west-2.amazonaws.com/prod/v1/log");
// IN same as EU
KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_IN}-${Constants.Stage.GAMMA}`, "https://99rhmafihd.execute-api.eu-west-1.amazonaws.com/prod/v1/log");

KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_NA}-${Constants.Stage.PROD}`, "https://gdw5e03sx2.execute-api.us-east-1.amazonaws.com/prod/v1/log");
KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_EU}-${Constants.Stage.PROD}`, "https://6zgtj6c9k1.execute-api.eu-west-1.amazonaws.com/prod/v1/log");
KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_FE}-${Constants.Stage.PROD}`, "https://icictbqbw7.execute-api.us-west-2.amazonaws.com/prod/v1/log");
KATAL_LOGGER_URL_MAP.set(`${Constants.Region.REGION_IN}-${Constants.Stage.PROD}`, "https://ao44lg1hs1.execute-api.eu-west-1.amazonaws.com/prod/v1/log");


export function getBaseURL() {
    // getting region and env values from AppData
    const region = AppDataStore.get(APP_DATA_KEYS.APP_REGION);
    const env = AppDataStore.get(APP_DATA_KEYS.APP_ENV).toLowerCase();
    const regionEnv = `${region}-${env}`;
    return BASE_URL_ENV_REGION_MAP.has(regionEnv) ? BASE_URL_ENV_REGION_MAP.get(regionEnv) : null;
}

export function getPUMARegion() {
    const region = AppDataStore.get(APP_DATA_KEYS.APP_REGION);
    if (region === Constants.Region.REGION_EU) {
        return Constants.RegionLowerCase.REGION_EU;
    } else if (region === Constants.Region.REGION_FE) {
        return Constants.RegionLowerCase.REGION_JP;
    } else if (region === Constants.Region.REGION_IN) {
        return Constants.RegionLowerCase.REGION_EU;
    }
    return Constants.RegionLowerCase.REGION_NA;
}

export function getPUMACountry() {
    const region = AppDataStore.get(APP_DATA_KEYS.APP_REGION);
    if (region === Constants.Region.REGION_EU) {
        return Constants.Region.REGION_UK;
    } else if (region === Constants.Region.REGION_FE) {
        return Constants.Region.REGION_JP;
    } else if (region === Constants.Region.REGION_IN) {
        return Constants.Region.REGION_IN;
    }
    // for region === REGIONS.NA or anything else
    return Constants.Region.REGION_US;
}

export function getPUMAStage() {
    const env = AppDataStore.get(APP_DATA_KEYS.APP_ENV);
    return env === Constants.Stage.PROD ? Constants.Stage.PROD : Constants.Stage.TEST;
}

export function getKatalLoggerURL() {
    // getting region and env values from AppData
    const region = AppDataStore.get(APP_DATA_KEYS.APP_REGION);
    const env = AppDataStore.get(APP_DATA_KEYS.APP_ENV).toLowerCase();
    const regionEnv = `${region}-${env}`;
    return KATAL_LOGGER_URL_MAP.has(regionEnv) ? KATAL_LOGGER_URL_MAP.get(regionEnv) : null;
}

export function getStageExecutionURL() {
    const region = AppDataStore.get(APP_DATA_KEYS.APP_REGION);
    const env = AppDataStore.get(APP_DATA_KEYS.APP_ENV).toLowerCase();
    const baseUrlData = PWADomainHelper.getVersionedBaseURLForModule(Modules.STAGE);
    let baseURL = baseUrlData.url[env][region];
    const version = baseUrlData.version;
    if(version) {
        baseURL += version + "/index.html";
    }
    return new URL(baseURL);
}
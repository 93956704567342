import AppDataStore, {APP_DATA_KEYS} from "../app-data";
import version from "../app-version";
import {Constants} from "@amzn/dolphin-web-framework";

export function getRegion() {
    // the hostname pattern is region-env-*.*
    const hostname = window.location.hostname;
    if (hostname.startsWith(Constants.RegionLowerCase.REGION_NA)) return Constants.Region.REGION_NA;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_EU)) return Constants.Region.REGION_EU;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_FE)) return Constants.Region.REGION_FE;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_IN)) return Constants.Region.REGION_IN;
    return Constants.Region.REGION_NA;
}

export function getAnalyticsRegion() {
    const hostname = window.location.hostname;
    if (hostname.startsWith(Constants.RegionLowerCase.REGION_NA)) return Constants.Region.REGION_NA;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_EU)) return Constants.Region.REGION_EU;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_FE)) return Constants.Region.REGION_FE;
    else if (hostname.startsWith(Constants.RegionLowerCase.REGION_IN)) return Constants.Region.REGION_EU;
    return Constants.Region.REGION_NA;
}

export function getEnv() {
    // the hostname pattern is region-env-*.*
    const hostname = window.location.hostname;
    const identifier = hostname.split(".")[0]; // we intent to get the region-env-*
    const env = identifier.split("-")[1];
    if (env === Constants.Stage.INTEG) return Constants.Stage.BETA;
    else if (env === Constants.Stage.MASTER) return Constants.Stage.GAMMA;
    else if (env === Constants.Stage.PROD) return Constants.Stage.PROD;
    return Constants.Stage.GAMMA;
}

export function updateAppRegionAndEnvironment() {
    let env = getEnv(), region = getRegion();
    // we don't support FE BETA
    if (env === Constants.Stage.BETA && region === Constants.Stage.FE) {
        region = Constants.Stage.NA;
    }
    AppDataStore.save(APP_DATA_KEYS.APP_ENV, env);
    AppDataStore.save(APP_DATA_KEYS.APP_REGION, region);
}

export function saveDataForApp() {
    try {
        // saving the region and env first
        updateAppRegionAndEnvironment();
        AppDataStore.save(APP_DATA_KEYS.APP_VERSION, version);
    } catch (e) {
        console.error("not able to parse data");
    }
}

import {ROUTE_PATH} from "../routing";

export const PlayerConstants = {
    PROCESS_PATH: "Parallel_Pick",
    VERSION: "V1.0.135.0"
};

export const HelpTypes = {
    PACKAGE_SCAN: 'PackageScan',
    CART_SCAN: 'CartScan',
    BAG_SCAN: "BagScan",
    DEFAULT: 'Default'
};

export const BagColor = {
    GRAY: "Gray",
    YELLOW: "Yellow"
};

export const ContainerType = {
    PACKAGE: "PACKAGE",
    BAG: "BAG",
};

export const ROUTE_PATH_FOR_PICKING = {
    PICK_ITEM: `${ROUTE_PATH.DO_PICKING}/`,
    WORKFLOW_TRANSITION: `${ROUTE_PATH.DO_PICKING}/workflow-transition/`,
    SCAN_CART_FOR_PICKING: `${ROUTE_PATH.DO_PICKING}/scan-cart-for-picking/`,
};

export const UPDATE_BACK_SELECT_STATE_DISABLED = true;
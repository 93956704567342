export const APP_DATA_KEYS = {
    ACTIVE_CART: "ACTIVE_CART",
    CART_HAS_ITEM: "CART_HAS_ITEM",
    PICKLIST: "PICKLIST",
    PICKLISTS_ASSIGNED_INFO: "PICKLISTS_ASSIGNED_INFO",
    ELIGIBLE_NODELIST: "ELIGIBLE_NODELIST",
    PREFERRED_NODE_FOR_PICKING: "PREFERRED_NODE_FOR_PICKING",
    WORKFLOW: "WORKFLOW",
    APP_MOCK_NETWORK: "APP_MOCK_NETWORK",
    ACCESS_TOKEN: "ACCESS_TOKEN",
    APP_REGION: "APP_REGION",
    APP_ENV: "APP_ENV",
    // APP VERSION
    APP_VERSION: "APP_VERSION",
    CART_STAGED_FOR_PICKLIST: "CART_STAGED_FOR_PICKLIST",
    // Redirected to Pick
    MODULE_REDIRECTED: "MODULE_REDIRECTED",
    STAGED_CART: "STAGED_CART",
    FEATURES: "FEATURES",
    MODULE_VERSIONS: "MODULE_VERSIONS",
    BARCODE_ENTERED: "BARCODE_ENTERED"
};

class AppData {

    save(key, value) {
        if (value === null || value === undefined) {
            sessionStorage.removeItem(key);
        } else {
            sessionStorage.setItem(key, JSON.stringify(value));
        }
    }

    remove(key) {
        sessionStorage.removeItem(key);
    }

    get(key) {
        const value = sessionStorage.getItem(key);
        try {
            return JSON.parse(value);
        } catch (e) {
            console.log(`error while parsing data for ${key} with value ${value}`);
            return null;
        }
    }

    clearPickingData() {
        AppDataStore.remove(APP_DATA_KEYS.PICKLIST);
        AppDataStore.remove(APP_DATA_KEYS.WORKFLOW);
        AppDataStore.remove(APP_DATA_KEYS.ACTIVE_CART);
        AppDataStore.remove(APP_DATA_KEYS.PICKLISTS_ASSIGNED_INFO);
    }

    saveActiveCart(cart, isNewCart) {
        this.save(APP_DATA_KEYS.ACTIVE_CART, cart);
        if(cart && isNewCart) {
            this.setCartHasItem(cart.id, false);
        }
    }

    getActiveCart() {
        return this.get(APP_DATA_KEYS.ACTIVE_CART);
    }

    savePicklist(picklist) {
        this.save(APP_DATA_KEYS.PICKLIST, picklist);
    }

    savePicklistsAssignedInfo(picklistsAssignedInfo) {
        this.save(APP_DATA_KEYS.PICKLISTS_ASSIGNED_INFO, picklistsAssignedInfo);
    }

    saveEligibleNodelist(nodeList) {
        this.save(APP_DATA_KEYS.ELIGIBLE_NODELIST, nodeList);
    }

    savePreferredNodeForPicking(nodeId) {
        this.save(APP_DATA_KEYS.PREFERRED_NODE_FOR_PICKING, nodeId);
    }

    getPicklist() {
        return this.get(APP_DATA_KEYS.PICKLIST);
    }

    getPicklistsAssignedInfo() {
        return this.get(APP_DATA_KEYS.PICKLISTS_ASSIGNED_INFO);
    }

    getEligibleNodelist() {
        return this.get(APP_DATA_KEYS.ELIGIBLE_NODELIST);
    }

    getPreferredNodeForPicking() {
        return this.get(APP_DATA_KEYS.PREFERRED_NODE_FOR_PICKING);
    }

    saveWorkflow(workflow) {
        this.save(APP_DATA_KEYS.WORKFLOW, workflow);
    }

    getWorkflow() {
        return this.get(APP_DATA_KEYS.WORKFLOW);
    }

    // we are never deleting the entry but depend on the session to expire or we replace the value
    // we are also handling the scenario where some items are picked and some are missed but they are not in a cart
    setCartHasItem(cartId, value) {
        AppDataStore.save(`${APP_DATA_KEYS.CART_HAS_ITEM}-${cartId}`, value);
    }

    doesCartHasItem(cartId) {
        return !!AppDataStore.get(`${APP_DATA_KEYS.CART_HAS_ITEM}-${cartId}`);
    }

    getIsCartStagedForPicklist() {
       return this.get(APP_DATA_KEYS.CART_STAGED_FOR_PICKLIST);
    }

    setIsCartStagedForPicklist(state) {
        this.save(APP_DATA_KEYS.CART_STAGED_FOR_PICKLIST, state);
    }

    wasModuleRedirected() {
        return this.get(APP_DATA_KEYS.MODULE_REDIRECTED);
    }

    redirectComplete() {
        this.save(APP_DATA_KEYS.MODULE_REDIRECTED, false);
    }

    redirectModule() {
        this.save(APP_DATA_KEYS.MODULE_REDIRECTED, true);
    }

    saveStagedCart(cart) {
        this.save(APP_DATA_KEYS.STAGED_CART, cart);
    }

    getStagedCart() {
        return this.get(APP_DATA_KEYS.STAGED_CART);
    }

    setBarcodeEntered(barcode) {
        this.save(APP_DATA_KEYS.BARCODE_ENTERED, barcode);
    }

    getBarcodeEntered() {
        return this.get(APP_DATA_KEYS.BARCODE_ENTERED);
    }

    saveFeatures(features) {
        this.save(APP_DATA_KEYS.FEATURES, features);
    }

    saveModuleVersions(versions) {
        this.save(APP_DATA_KEYS.MODULE_VERSIONS, versions);
    }

    getFeatures() {
        return this.get(APP_DATA_KEYS.FEATURES);
    }

    getModuleVersions() {
        return this.get(APP_DATA_KEYS.MODULE_VERSIONS);
    }
}

const AppDataStore = new AppData();

export default AppDataStore;
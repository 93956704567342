export const ApplicationActions = {
    TASK_SELECTION: "TASK_SELECTION",
    ENTER_ROUTE_CODE: "ENTER_ROUTE_CODE",
    NEW_CART_SCAN: "NEW_CART_SCAN",
    CART_SCAN: "CART_SCAN",
    ITEM_SCAN: "ITEM_SCAN",
    CART_FULL: "CART_FULL",
    HELP_OPTION_SELECTED: "HELP_OPTION_SELECTED",
    ITEM_SUMMARY: "ITEM_SUMMARY",
    ITEM_SUMMARY_CONTINUE_OPTION: "ITEM_SUMMARY_CONTINUE_OPTION",
    STAGING_LOCATION_SCANNED: "STAGING_LOCATION_SCANNED",
    PICKING_COMPLETE: "PICKING_COMPLETE",
    UPDATE_STATE: "UPDATE_STATE",
    DIRECTED_PICK_ENTRY: "DIRECTED_PICK_ENTRY",
    OPEN_HELP_OPTION: "OPEN_HELP_OPTION",
    ON_BACK_SELECT: "ON_BACK_SELECT",
    ACKNOWLEDGE_AND_EXIT: "ACKNOWLEDGE_AND_EXIT",
    MIXED_CART_NOTICE: "MIXED_CART_NOTICE",
    EMPTY_CART_CONFIRMATION: "EMPTY_CART_CONFIRMATION",
    ROUTE_CONFIRMATION: "ROUTE_CONFIRMATION",
    UPDATE_BACK_SELECT_STATE: "UPDATE_BACK_SELECT_STATE",
    ON_BACK_DROP_OPEN: "ON_BACK_DROP_OPEN",
    ON_EXIT_MODULE: "ON_EXIT_MODULE",

};

export const Operations = {
    VALID_ROUTE_CODE_ENTERED: "VALID_ROUTE_CODE_ENTERED",
    INVALID_ROUTE_CODE_ENTERED: "INVALID_ROUTE_CODE_ENTERED",
    VALID_CART_SCAN: "VALID_CART_SCAN",
    INVALID_ITEM_SCAN: "INVALID_ITEM_SCAN",
    VALID_ITEM_SCAN: "VALID_ITEM_SCAN",
    ITEM_MISSING: "PACKAGE_MISSING",
    ITEM_DAMAGED: "ITEM_DAMAGED",
    MANUAL_SCAN: "MANUAL_SCAN",
    CART_NOT_FOUND: "CART_NOT_FOUND",
    INVALID_LOCATION_SCAN: "INVALID_LOCATION_SCAN",
    VALID_LOCATION_SCAN: "VALID_LOCATION_SCAN",
    PICK_MORE: "PICK_MORE",
    END_PICKING: "END_PICKING",
    DIRECTED_ITEM_PICKED: "DIRECTED_ITEM_PICKED",
    ITEM_SKIPPED_NOTIFICATION: "ITEM_SKIPPED_NOTIFICATION",
    MOVED_TO_PSBIN: "MOVED_TO_PSBIN",
    INCORRECT_PSBIN: "INCORRECT_PSBIN",
    INCORRECT_CART: "INCORRECT_CART",
    VALID_DAMAGED_ITEM_SCAN: "VALID_DAMAGED_ITEM_SCAN",
    ITEM_REMOVED_FROM_PICKLIST: "ITEM_REMOVED_FROM_PICKLIST",
    WRONG_BARCODE: "WRONG_BARCODE",
    ACTION_ACKNOWLEDGE: "ACTION_ACKNOWLEDGE",
    ACTION_DECLINE: "ACTION_DECLINE"
};
export const USER_PROFILE_DETAIL = {
    PICKING_MULTIPLE_BAG: "PICKING_MULTIPLE_BAG",
}

class UserProfileDetailStore {

    constructor() {
        this.userDetail = new Map();
        // TODO: fill up the default values or remove the below entries
        this.userDetail.set(USER_PROFILE_DETAIL.PICKING_MULTIPLE_BAG, true);
    }

    isUserEnabledPickingMultipleBag() {
        // TODO: we are not getting this value as of now
        return this.userDetail.has(USER_PROFILE_DETAIL.PICKING_MULTIPLE_BAG)
            && this.userDetail.get(USER_PROFILE_DETAIL.PICKING_MULTIPLE_BAG);
    }

    saveUserDetail(detailMap) {
        if (!detailMap) {
            return;
        }
        // TODO save
        console.log(detailMap);
    }
}

const UserProfileDetail = new UserProfileDetailStore();

export default UserProfileDetail;
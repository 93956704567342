/**
 * this file should not use any application related functions
 */

import React from "react";
import axios from "axios";
import Loader from "@amzn/meridian/loader";
import Column from "@amzn/meridian/column";
import * as serviceWorker from '../serviceWorker';

const MAX_WAIT_TIME = 7000; // 7 seconds
const APP_HOME = "/home";

async function isUpdateAvailable() {
    let sw = localStorage.getItem("sw");
    try {
        const response = await axios.get("./service-worker.js");
        // if we have sw and it doesn't match with the earlier one
        if (sw && sw !== response.data) {
            sw = response.data;
            return true;
        } else {
            sw = response.data;
            return false;
        }
    } catch (e) {
        return false;
    } finally {
        localStorage.setItem("sw", sw);
    }
}

export default function AppLauncher(props) {

    const goToAppHome = () => {
        props.history.replace(APP_HOME);
    }

    const updateAppIfAvailable = async () => {
        try {
            if (await isUpdateAvailable()) {
                const result = await serviceWorker.update();
                console.log("update done", result);
                window.location.reload();
            } else {
                throw new Error("NO_UPDATE");
            }
        } catch (e) {
            goToAppHome();
        }
    }

    React.useEffect(() => {
        updateAppIfAvailable();
        // we are moving on after the MAX_WAIT_TIME in worst case scenario
        const timer = setTimeout(() => {
            goToAppHome();
        }, MAX_WAIT_TIME);
        return () => clearTimeout(timer);
    }, []);

    return <Column heights={"fill"}>
        <Column alignmentVertical={"center"} alignmentHorizontal={"center"}>
            <Loader type={"circular"} size={"medium"}/>
        </Column>
    </Column>;
}

import React, {createContext, useReducer} from "react";
import {AppContextActions, AppContextReducer, DEFAULT_VALUE} from "../reducers/app-context-reducer";
import {injectIntl} from "react-intl";
import EventNames from "../analytics/event-names";
import MetricKeys from "../analytics/metric-keys";
import {MobileAnalyticsHelper} from "@amzn/dolphin-web-framework";
import {ApplicationActions} from "../player/action/ApplicationActions";
import {PlayerContext} from "../player/context/PlayerContext";

export const AppContext = createContext(DEFAULT_VALUE);

const AppContextProvider = (props) => {
    const [appState, dispatchApp] = useReducer(AppContextReducer, DEFAULT_VALUE);
    const {dispatchPlayer} = React.useContext(PlayerContext);

    const formatMessage = (id, options) => {
        return props.intl.formatMessage({id: id}, options);
    }

    const updatePicklist = (value) => {
        dispatchApp({type: AppContextActions.UPDATE_PICKLIST_DETAILS, value: value});
    }

    const updateWorkflow = (value) => {
        dispatchApp({type: AppContextActions.UPDATE_WORKFLOW, value: value});
    }

    const setupHeader = (title, onBackPressed) => {
        dispatchApp({type: AppContextActions.SETUP_HEADER, value: {title, onBackPressed}});
    }

    const setupHelp = (enabled, options) => {
        dispatchApp({type: AppContextActions.SETUP_HELP, value: {enabled, options}});
    }

    const openHelp = () => {
        dispatchPlayer({type: ApplicationActions.OPEN_HELP_OPTION});
        dispatchApp({type: AppContextActions.OPEN_HELP});
    }

    const closeHelp = (isUpdateStateDisabled) => {
        dispatchPlayer({type: ApplicationActions.UPDATE_BACK_SELECT_STATE, data: {value: isUpdateStateDisabled}});
        dispatchApp({type: AppContextActions.CLOSE_HELP});
    }

    const setHeaderBackground = (color) => {
        dispatchApp({type: AppContextActions.SET_HEADER_COLOR, value: color});
    }

    const openBackDrop = (options) => {
        dispatchPlayer({type: ApplicationActions.ON_BACK_DROP_OPEN});
        MobileAnalyticsHelper.processAnalytics(Date.now(), MetricKeys.BACK_DIALOG_LOADED, "",
            MetricKeys.DIRECTED_PICKING, "","", EventNames.USER_LOADED_SCREEN);
        dispatchApp({type: AppContextActions.OPEN_BACK_DROP, value: options});
    }

    const closeBackDrop = (success) => {
        dispatchPlayer({type: ApplicationActions.UPDATE_BACK_SELECT_STATE, data: {value: success}});
        MobileAnalyticsHelper.processAnalytics(Date.now(), MetricKeys.BACK_DIALOG_BUTTON_CLICK, "",
            MetricKeys.DIRECTED_PICKING, "","", EventNames.USER_LOADED_SCREEN, !success);
        dispatchApp({type: AppContextActions.CLOSE_BACK_DROP});
    }

    const openDialog = (options) => {
        dispatchApp({type: AppContextActions.OPEN_DIALOG, value: options});
    }

    const closeDialog = () => {
        dispatchApp({type: AppContextActions.CLOSE_DIALOG});
    }

    return (
        <AppContext.Provider value={{
            appState, formatMessage, appActions: {
                updatePicklist,
                updateWorkflow,
                setupHeader,
                setupHelp,
                openHelp,
                closeHelp,
                openBackDrop,
                closeBackDrop,
                setHeaderBackground,
                openDialog,
                closeDialog
            }
        }}>
            {props.children}
        </AppContext.Provider>
    )
}

export default injectIntl(AppContextProvider);
const EventNames = {
    USER_LOADED_SCREEN: "user_loaded_screen",
    DIRECTED_PICKING_ASSIGNED_ROUTE: "directed_picking_assigned_route",
    DIRECTED_PICKING_ELIGIBLE_NODE_LIST: "directed_picking_eligible_node_list",
    DIRECTED_PICKING_CART_OPERATION: "directed_picking_cart_operation",
    DIRECTED_PICKING_CART_OPERATION_CLOSE: "directed_picking_cart_operation_close",
    DIRECTED_PICKING_CART_OPERATION_DETACH: "directed_picking_cart_operation_detach",
    DIRECTED_PICKING_CART_OPERATION_STAGING: "directed_picking_cart_operation_stage",
    DIRECTED_PICKING_CART_OPERATION_PRE_STAGING: "directed_picking_cart_operation_pre_stage",
    DIRECTED_PICKING_PACKAGE_OPERATION: "directed_picking_package_operation",
    DIRECTED_PICKING_BAG_PICKED_IN_ORDER: "directed_picking_bag_picked_in_order",
    DIRECTED_ROUTE_PICKING_COMPLETE: "directed_route_picking_complete"
}

export default EventNames;
const ErrorCodes = {
    INTERNAL_ERROR: 'INTERNAL_ERROR',
    ASSOCIATE_ALREADY_ASSIGNED: 'ASSOCIATE_ALREADY_ASSIGNED',
    NO_ASSIGNABLE_PICKLIST: 'NO_ASSIGNABLE_PICKLIST',
    NO_OPTIMAL_PICKLIST: 'NO_OPTIMAL_PICKLIST',
    MANUAL_ASSIGN_LIMIT_EXCEEDED: 'MANUAL_ASSIGN_LIMIT_EXCEEDED',
    PICKLIST_ALREADY_ASSIGNED: 'PICKLIST_ALREADY_ASSIGNED',
    PICKLIST_DOES_NOT_EXIST: 'PICKLIST_DOES_NOT_EXIST',
    CART_DOES_NOT_EXIST: 'CART_DOES_NOT_EXIST',
    CART_INCORRECT: 'CART_INCORRECT',
    PICKLIST_NOT_ASSIGNED_TO_ASSOCIATE: 'PICKLIST_NOT_ASSIGNED_TO_ASSOCIATE',
    CART_ALREADY_ADDED: 'CART_ALREADY_ADDED',
    CART_ALREADY_IN_USE: 'CART_ALREADY_IN_USE',
    CART_NOT_EMPTY: 'CART_NOT_EMPTY',
    INVALID_STAGING_AREA_ID: 'INVALID_STAGING_AREA_ID',
    RESOURCE_TYPE_INCORRECT: 'RESOURCE_TYPE_INCORRECT',
    CART_ALREADY_STAGED: 'CART_ALREADY_STAGED',
    PICKLIST_ITEM_DONT_MATCH: 'PICKLIST_ITEM_DONT_MATCH',
    INVALID_MOVE: 'INVALID_MOVE',
    INVALID_PICKLIST_ID: 'INVALID_PICKLIST_ID',
    CONTAINER_DOES_NOT_EXIST: 'CONTAINER_DOES_NOT_EXIST',
    PROBLEM_SOLVE_BIN_INVALID: 'PROBLEM_SOLVE_BIN_INVALID',
    PICKLIST_COMPLETED: 'PICKLIST_COMPLETED',
    PICKLIST_DEPARTED: 'PICKLIST_DEPARTED',
    PICKLIST_UNASSIGNED: 'PICKLIST_UNASSIGNED',
    PICKLIST_NOT_IN_PROGRESS: 'PICKLIST_NOT_IN_PROGRESS',
    PICKLIST_ITEM_UNAVAILABLE: 'PICKLIST_ITEM_UNAVAILABLE',
    INVALID_CART_STATUS: 'INVALID_CART_STATUS',
    STAGING_LOCATION_UPDATED: 'STAGING_LOCATION_UPDATED',
    PRE_STAGING_AREA_FULL: 'PRE_STAGING_AREA_FULL',
    PRE_STAGE_AREA_NOT_CONFIGURED: 'PRE_STAGE_AREA_NOT_CONFIGURED',
    CONTAINER_OPEN: 'CONTAINER_OPEN',
    TRAILER_NOT_FOUND: 'TRAILER_NOT_FOUND',
    DEFAULT: 'DEFAULT',
    AUTH_ERROR: 'AUTH_ERROR'
}

const ErrorMessages = {
    INTERNAL_ERROR: 'Retry action. Pick server error',
    ASSOCIATE_ALREADY_ASSIGNED: 'You have been assigned route {0}',
    NO_ASSIGNABLE_PICKLIST: 'There is no route for picking',
    NO_OPTIMAL_PICKLIST: 'Not allocating route to prevent aisle overcrowding and advance picking. Please re-try shortly.',
    PICKLIST_ALREADY_ASSIGNED: 'Route has been assigned to {0}',
    PICKLIST_DOES_NOT_EXIST: 'This Route does not exist',
    CART_DOES_NOT_EXIST: 'Scanned item is not a cart',
    CART_INCORRECT: 'Invalid cart {0}',
    PICKLIST_NOT_ASSIGNED_TO_ASSOCIATE: 'This route is not assigned to you',
    CART_ALREADY_ADDED: 'Cart already added to route',
    CART_ALREADY_IN_USE: 'Cart belongs to route %s',
    INVALID_STAGING_AREA_ID: 'Staging location is not right',
    RESOURCE_TYPE_INCORRECT: 'Staging location is not right',
    CART_ALREADY_STAGED: 'Cart is already staged',
    PICKLIST_ITEM_DONT_MATCH: 'Item does not belong to picklist',
    INVALID_MOVE: 'Can not move item to cart',
    INVALID_PICKLIST_ID: 'Invalid route code',
    CONTAINER_DOES_NOT_EXIST: 'Please scan the correct item',
    PROBLEM_SOLVE_BIN_INVALID: 'Incorrect problem solve bin',
    PICKLIST_COMPLETED: 'Route is already picked',
    PICKLIST_DEPARTED: 'Route has been departed',
    PICKLIST_UNASSIGNED: 'Picklist not assigned',
    PICKLIST_ITEM_UNAVAILABLE: 'Picklist item unavailable',
    INVALID_CART_STATUS: 'Cart is not ready to stage',
    STAGING_LOCATION_UPDATED: 'Stage location for this route has been updated to {0}',
    PRE_STAGING_AREA_FULL: 'Picking paused. Please stage the carts waiting in pre-staging area to resume.',
    PRE_STAGE_AREA_NOT_CONFIGURED: 'Pre Staging Area not configured',
    CONTAINER_OPEN: 'Container %s is being used for stow',
    TRAILER_NOT_FOUND: 'No trailer is attached to the dock door',
    DEFAULT: 'Retry action. Pick server error',
    AUTH_ERROR: 'Authentication failure'
}

export function getErrorMessageFromCode(code, argsOrder, errorMap) {
    let message = (code && ErrorMessages[code.toUpperCase()]) || ErrorMessages.DEFAULT;
    argsOrder && argsOrder.forEach((args, index) => {
        if (errorMap && errorMap[args]) {
            message = message.replace(`{${index}}`, errorMap[args]);
        }
    });
    return message;
}

export default ErrorCodes;
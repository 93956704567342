const ProgramType = {
    AMZL: "AMZL",
    AR: "AR",
    GSF: "GSF",
    SSD: "SSD",
    BOOMERANG: "BOOMERANG",
    AR_NEXT_GEN: "AR_NEXT_GEN"
}


export default ProgramType;
export const Modules = {
    PICK: 'PICK',
    STAGE: 'STAGE'
};

export const MODULE_NAME = 'DirectedPick';

//training status
export const TRAINING_STATUS = {
    EXPIRED: 'EXPIRED'
};
const MetricKeys = {
    DIRECTED_PICKING: "DIRECTED_PICKING",
    DIRECTED_STAGING: "DIRECTED_STAGING",
    DECOUPLED_STAGING: "DECOUPLED_STAGING",
    DECOUPLED_PICKING: "DECOUPLED_PICKING",
    MANUAL: "MANUAL",
    AUTO: "AUTO",
    CART_VERIFIED: "CART_VERIFIED",
    CART_NOT_FOUND: "CART_NOT_FOUND",
    CART_ADD: "CART_ADD",
    REPORT_ISSUE: "REPORT_ISSUE",
    BACK_DIALOG_LOADED: "BACK_DIALOG_LOADED",
    BACK_DIALOG_BUTTON_CLICK: "BACK_DIALOG_BUTTON_CLICK",
    CONTAINER_STAGING: "CONTAINER_STAGING"
}

export default MetricKeys;
import React from "react";
import {PUMAMetricHelper, Logger, NativeMeshInteractor} from "@amzn/dolphin-web-framework";
import {ErrorView} from "@amzn/dolphin-uiweb-framework";

export default class AppError extends React.Component {

    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }

    static getDerivedStateFromError(error) {
        return {hasError: true};
    }

    componentDidCatch(error, errorInfo) {
        Logger.log.fatal("ErrorBoundary", error, errorInfo);
        const metricName = "clientError";
        PUMAMetricHelper.publishCountToDolphinCSM(metricName);
    }

    render() {
        if (this.state.hasError || !this.props.children) {
            return <ErrorView title={"Pick"} errorMessage={"pick.error"} onBackClicked={NativeMeshInteractor.exitModule}/>;
        }
        return this.props.children;
    }
}